<template>
    <SmartLinkItemComponent custom-tag="div" class="image-block" v-if="model" :id="`_${model.system.id}`"
        :component="!model.system.workflow">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg">
                    <SmartLinkStandaloneElement custom-tag="h2" class="title" :codename="type.elements.title.codename">
                        {{ data.title }}
                    </SmartLinkStandaloneElement>
                </div>
                <div class="col-lg">
                    <SmartLinkStandaloneElement custom-tag="div" class="cta"
                        :codename="type.elements.call_to_action.codename">
                        <CallToAction custom-class="btn btn-outline-primary btn-sm" custom-target="_blank" v-for="cta in data.ctas"
                            :model="cta" :hasIcon="true" />
                    </SmartLinkStandaloneElement>
                </div>
            </div>
            <div class="row">
                <div class="col-lg">
                    <SmartLinkStandaloneElement custom-tag="div" class="image" :codename="type.elements.image.codename">
                        <NuxtImg class="img-fluid" provider="kontentAiProvider" v-for="img in data.images"
                            :src="img.url"></NuxtImg>
                    </SmartLinkStandaloneElement>
                    <SmartLinkStandaloneElement custom-tag="div" class="caption"
                        :codename="type.elements.caption.codename">
                        {{ data.caption }}
                    </SmartLinkStandaloneElement>
                </div>
            </div>
        </div>
    </SmartLinkItemComponent>
</template>

<script lang="ts" setup>
import { type CallToActionModel, type ImageBlockModel, contentTypes } from '~/models';
import type { AnchorNavigationBarItem } from '~/types/AnchorNavigationBarItem';

const pageStore = usePageStore();
const props = defineProps<{ model: ImageBlockModel }>();
const type = contentTypes.image_block;

const data = computed(() => {
    return {
        title: props.model.elements.title.value,
        images: props?.model?.elements?.image?.value,
        caption: props.model.elements.caption.value,
        ctas: pageStore.getListAs<CallToActionModel>(props?.model?.elements?.callToAction?.value),
        anchorTagNavigationMenuTitle: props.model.elements.anchorTagNavigationMenuTitle.value,
        anchorTagNavigationShowInNavigation: firstOrDefault(props?.model?.elements?.anchorTagNavigationShowInNavigation?.value)
    };
})

const navBarState = useState<AnchorNavigationBarItem[]>('navBarState');
const AnchorNavBarOrder = useState<string[]>('AnchorNavBarOrder');

if (multipleChoiceToBoolean(props.model.elements.anchorTagNavigationShowInNavigation)) {

    navBarState.value.push({ text: props.model.elements.anchorTagNavigationMenuTitle.value, link: `#${props.model.system.id}`, index: AnchorNavBarOrder.value.indexOf(props.model.system.codename)});
}
</script>